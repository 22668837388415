import React, { useEffect, useState } from "react";
import BarChart from "./BarChart/BarChart";
import { Button, Col, Divider, Row, Space, Typography, message } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import actions from "../actions";

const AdminBarChart = ({
  height,
  options,
  entry,
  country,
  state,
  year,
  type,
  data,
}) => {
  const currentYear = new Date().getFullYear();
  const minYear = 2018;
  const [selectedYear, setSelectedYear] = useState(year);
  const [barData, setBarData] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [total, setTotal] = useState(0);

  const summaryData = async () => {
    const params = {
      country: country,
      state: state,
      year: selectedYear,
      status: "ACTIVE",
    };
    const payload = {
      channel: entry?.options,
      year: selectedYear,
    };

    const resp = await actions.registeredUserSummary(params, payload);

    if (resp.success) {
      if (type === "totalUsers") {
        setBarData(resp?.data?.totalRegistrationsForYear || {});
        setTotal(resp?.data?.totalRegistrationsForYear["TOTAL"] || 0);
      } else {
        setBarData(
          resp?.data?.totalRegistrationsByChannelAndMonth[0][entry.options] ||
            {}
        );
        setTotal(
          resp?.data?.totalRegistrationsByChannelAndMonth[0][entry.options]
            ?.TOTAL || 0
        );
      }
    } else {
      message.error(resp.msg);
    }
  };

  const generateMonthData = (barData) => {
    const months = [
      "JANUARY",
      "FEBRUARY",
      "MARCH",
      "APRIL",
      "MAY",
      "JUNE",
      "JULY",
      "AUGUST",
      "SEPTEMBER",
      "OCTOBER",
      "NOVEMBER",
      "DECEMBER",
    ];
    const monthValues = months.map((month) => barData[month] || 0);

    return {
      labels: months.map((month) => month.substring(0, 3)),
      datasets: [
        {
          data: monthValues,
          backgroundColor: "#91BDEC",
          borderColor: "#91BDEC",
          barThickness: 8,
          borderRadius: 5,
        },
      ],
    };
  };

  useEffect(() => {
    setSelectedYear(year);
  }, [year]);

  useEffect(() => {
    summaryData();
  }, [selectedYear, state, country]);

  useEffect(() => {
    if (barData) {
      const generatedChartData = generateMonthData(barData);
      setChartData(generatedChartData);
    }
  }, [barData]);
  const handlePrevYear = () => {
    setSelectedYear((prev) => (prev > minYear ? prev - 1 : prev));
  };

  const handleNextYear = () => {
    setSelectedYear((prev) => (prev < currentYear ? prev + 1 : prev));
  };

  return (
    <div
      style={{
        background: "#fff",
        marginTop: "2rem",
        padding: "24px",
        borderRadius: "10px",
      }}
    >
      <Row>
        <Col span={12}>
          {entry?.options}: {total}
        </Col>
        <Col style={{ display: "flex", justifyContent: "flex-end" }} span={12}>
          <Space>
            <Button
              style={{ height: "18px", width: "18px" }}
              icon={<LeftOutlined style={{ fontSize: "12px" }} />}
              onClick={handlePrevYear}
            />
            <Typography.Text>{selectedYear}</Typography.Text>
            <Button
              style={{ height: "18px", width: "18px" }}
              icon={<RightOutlined style={{ fontSize: "12px" }} />}
              onClick={handleNextYear}
            />
          </Space>
        </Col>
      </Row>
      <Divider style={{ margin: "8px 0" }} />
      {chartData && (
        <BarChart height={height} data={chartData} options={options} />
      )}
    </div>
  );
};

export default AdminBarChart;
