import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import actions from "../actions";
import { Typography, Space, Row, Col, Button, Divider, message } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const DonutChart = ({ label, country, state, year }) => {
  const currentYear = new Date().getFullYear();
  const minYear = 2018;
  const [selectedYear, setSelectedYear] = useState(year);
  const [pieData, setPieData] = useState([]);

  const handlePrevYear = () => {
    setSelectedYear((prev) => (prev > minYear ? prev - 1 : prev));
  };

  const handleNextYear = () => {
    setSelectedYear((prev) => (prev < currentYear ? prev + 1 : prev));
  };
  const summaryData = async () => {
    const params = {
      country: country,
      state: state,
      year: selectedYear,
      status: "ACTIVE",
    };
    const payload = {};

    const resp = await actions.registeredUserSummary(params, payload);

    if (resp.success) {
      setPieData(resp?.data?.totalRegistrationsByChannel);
    } else {
      message.error(resp.msg);
    }
  };

  useEffect(() => {
    setSelectedYear(year);
  }, [year]);

  useEffect(() => {
    summaryData();
  }, [selectedYear, country, state]);
  const data = {
    labels: Object.keys(pieData).filter((key) => key !== "TOTAL"),
    datasets: [
      {
        data: Object.entries(pieData)
          .filter(([key]) => key !== "TOTAL")
          .map(([_, value]) => value),
        backgroundColor: [
          "rgb(54, 162, 235)",
          "rgb(75, 192, 192)",
          "rgb(255, 205, 86)",
          "rgb(255, 99, 132)",
          "rgb(201, 203, 207)",
          "rgb(153, 102, 255)",
          "rgb(102, 102, 153)",
          "rgb(255, 159, 64)",
        ],
        hoverOffset: 4,
        cutout: "60%",
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div
      style={{
        background: "#fff",
        marginTop: "2rem",
        padding: "24px",
        borderRadius: "10px",
      }}
    >
      <Row>
        <Col span={12}></Col>
        <Col style={{ display: "flex", justifyContent: "flex-end" }} span={12}>
          <Space>
            <Button
              style={{ height: "18px", width: "18px" }}
              icon={<LeftOutlined style={{ fontSize: "12px" }} />}
              onClick={handlePrevYear}
            />
            <Typography.Text>{selectedYear}</Typography.Text>
            <Button
              style={{ height: "18px", width: "18px" }}
              icon={<RightOutlined style={{ fontSize: "12px" }} />}
              onClick={handleNextYear}
            />
          </Space>
        </Col>
      </Row>
      <Divider style={{ margin: "8px 0" }} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "300px",
        }}
      >
        <div style={{ width: "40%", position: "relative" }}>
          <Doughnut data={data} options={options} />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              fontSize: "24px",
              fontWeight: "bold",
              color: "#666",
            }}
          >
            {pieData.TOTAL}
          </div>
        </div>
        <div style={{ marginLeft: "20px" }}>
          <Space direction="vertical">
            {data.labels.map((label, index) => (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center" }}
              >
                <span
                  style={{
                    display: "inline-block",
                    width: "10px",
                    height: "10px",
                    borderRadius:"10px",
                    backgroundColor: data.datasets[0].backgroundColor[index],
                    marginRight: "8px",
                  }}
                />
                <Typography.Text>{label}</Typography.Text>
              </div>
            ))}
          </Space>
        </div>
      </div>
    </div>
  );
};

export default DonutChart;
